#mobile-background {
  height: 1px;
  border-radius: 0 0 100% 100%;
  visibility: hidden;
  will-change: width, height;
  transition: height 0.25s cubic-bezier(0.75, 0.13, 0.9, 0.62),
    border-radius 0.15s cubic-bezier(0.75, 0.13, 0.9, 0.62), visibility 10s;
}

#mobile-background:not(.open) {
  pointer-events: none;
}

#mobile-background.open {
  visibility: visible;
  height: 100vh;
  border-radius: 0;
  transition: height 0.25s cubic-bezier(0.04, -0.1, 0.29, 0.98),
    border-radius 0.5s cubic-bezier(0.04, -0.1, 0.29, 0.98), visibility 0.25s;
}

#mobile-background #nav-link {
  opacity: 0;
  transform: translateY(-40px);
  transition: opacity 0.25s cubic-bezier(0.75, 0.13, 0.9, 0.62),
    transform 0.25s cubic-bezier(0.75, 0.13, 0.9, 0.62);
}

#mobile-background.open #nav-link {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.1s cubic-bezier(0.04, -0.1, 0.29, 0.98) 0.05s,
    transform 0.25s cubic-bezier(0.04, -0.1, 0.29, 0.98) 0.05s;
}

#nav-link a:active {
  color: black;
}
