#nav-icon3 {
  --nav-icon-bar-height: 4px; /* can set this from parent */
  width: calc(
    calc(var(--nav-icon-bar-height) * 5) * 1.33
  ); /* height + 1/3rd of height */
  height: calc(var(--nav-icon-bar-height) * 5);
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: var(--nav-icon-bar-height);
  width: 100%;
  background: var(--nav-icon-closed-background, white);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

#nav-icon3.open span {
  background: var(--nav-icon-open-background, black);
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  /* top: 18px; */
  top: calc(var(--nav-icon-bar-height) * 2);
}

#nav-icon3 span:nth-child(4) {
  /* top: 36px; */
  top: calc(var(--nav-icon-bar-height) * 4);
}

#nav-icon3.open span:nth-child(1) {
  top: calc(var(--nav-icon-bar-height) * 2);
  /* top: 18px; */
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: calc(var(--nav-icon-bar-height) * 2);
  /* top: 18px; */
  width: 0%;
  left: 50%;
}
