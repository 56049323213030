@tailwind base;
@tailwind components;
@tailwind utilities;

/* svg {
  vertical-align: baseline; Fix for tailwind <-> antd compatibility, button icon position 
} */

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum";
}

* {
  box-sizing: border-box;
}

.theme-dark {
  --color-base: #000000;
  --color-highlight: #ffffff;
}

.theme-light {
  --color-base: #ffffff;
  --color-highlight: #000000;
}
